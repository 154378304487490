import React, { useState, useEffect, useRef } from "react"
import { addPropertyControls, ControlType } from "framer"

export default function Countdown({
    fontSize = 48,
    fontFamily = "Inter",
    fontColor = "#FFFFFF",
    fontWeight = "bold",
    endDate = "2024-12-03",
    endTime = "00:00:00",
    endMessage = "Time's up!",
    boxColor = "rgba(255, 255, 255, 0.1)",
    padding = "0px",
}) {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())
    const prevTimeLeft = useRef(timeLeft)

    useEffect(() => {
        const timer = setInterval(() => {
            prevTimeLeft.current = timeLeft
            setTimeLeft(calculateTimeLeft())
        }, 1000)

        return () => clearInterval(timer)
    }, [timeLeft])

    function calculateTimeLeft() {
        const endDateTime = new Date(`${endDate}T${endTime}`)
        const now = new Date()
        const difference = endDateTime - now

        if (isNaN(endDateTime.getTime())) {
            console.error("Invalid date or time format")
            return null
        }

        if (difference > 0) {
            return {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            }
        } else {
            return null
        }
    }

    function renderNumber(value, previousValue) {
        return (
            <div
                style={{
                    position: "relative",
                    height: `${fontSize * 1.2}px`,
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        fontSize: `${fontSize}px`,
                        fontFamily,
                        color: fontColor,
                        fontWeight,
                        transform: "translateY(0)",
                        animation:
                            previousValue !== value
                                ? "slide-out 0.5s forwards"
                                : "none",
                    }}
                >
                    {String(previousValue).padStart(2, "0")}
                </div>

                <div
                    style={{
                        position: "absolute",
                        fontSize: `${fontSize}px`,
                        fontFamily,
                        color: fontColor,
                        fontWeight,
                        transform: "translateY(100%)",
                        animation:
                            previousValue !== value
                                ? "slide-in 0.5s forwards"
                                : "none",
                    }}
                >
                    {String(value).padStart(2, "0")}
                </div>

                <style>
                    {`
                        @keyframes slide-in {
                            0% {
                                transform: translateY(100%);
                            }
                            100% {
                                transform: translateY(0%);
                            }
                        }

                        @keyframes slide-out {
                            0% {
                                transform: translateY(0%);
                            }
                            100% {
                                transform: translateY(-100%);
                            }
                        }
                    `}
                </style>
            </div>
        )
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
                flexWrap: "wrap",
                padding,
            }}
        >
            {timeLeft ? (
                Object.entries(timeLeft).map(([unit, value]) => (
                    <div
                        key={unit}
                        style={{
                            textAlign: "center",
                            padding: "0.5rem",
                            backgroundColor: boxColor,
                            borderRadius: "8px",
                            minWidth: "60px",
                            position: "relative",
                            flex: "1 1 80px",
                        }}
                    >
                        {renderNumber(
                            value,
                            prevTimeLeft.current
                                ? prevTimeLeft.current[unit]
                                : value
                        )}

                        <div
                            style={{
                                fontSize: `${fontSize * 0.4}px`,
                                fontFamily,
                                color: fontColor,
                                textTransform: "uppercase",
                                marginTop: "0.5rem",
                            }}
                        >
                            {unit}
                        </div>
                    </div>
                ))
            ) : (
                <span
                    style={{
                        fontSize: `${fontSize}px`,
                        fontFamily,
                        color: fontColor,
                        fontWeight,
                    }}
                >
                    {endMessage}
                </span>
            )}
        </div>
    )
}

// Property Controls
addPropertyControls(Countdown, {
    fontSize: {
        type: ControlType.Number,
        unit: "px",
        defaultValue: 48,
    },
    fontFamily: {
        type: ControlType.String,
        defaultValue: "Inter",
    },
    fontColor: {
        type: ControlType.Color,
        defaultValue: "#FFFFFF",
    },
    fontWeight: {
        type: ControlType.Enum,
        defaultValue: "bold",
        options: [
            "normal",
            "bold",
            "bolder",
            "lighter",
            100,
            200,
            300,
            400,
            500,
            600,
            700,
            800,
            900,
        ],
    },
    endDate: {
        type: ControlType.String,
        defaultValue: "2024-12-03",
        description: "YYYY-MM-DD format",
    },
    endTime: {
        type: ControlType.String,
        defaultValue: "00:00:00",
        description: "HH:MM:SS format",
    },
    endMessage: {
        type: ControlType.String,
        defaultValue: "Time's up!",
    },
    boxColor: {
        type: ControlType.Color,
        defaultValue: "rgba(255, 255, 255, 0.1)",
    },
    padding: {
        type: ControlType.String,
        defaultValue: "0px",
        description: "Padding for the parent container (e.g., '10px', '1rem')",
    },
})
